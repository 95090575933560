import axios from 'axios';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';
import { networkId } from '../config';

import { setLoginData, setKYC, setKYB, setKycApproved, setKybApproved, toggleStatusModal, setAllDonations, setDonationsNames, setUser, setUriData, setRewardTokens } from '../actions/Auth';

/*========== LOGIN FUNCTIONS =============*/

export function* login({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/users/auth', payload });
  if (error) {
    yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    const decoded = jwt_decode(response["data"]["body"]["token"]);
    if (decoded["role"] !== "admin") {
      EventBus.publish("error", "Can't login through User account ");
      return;
    }
    yield put(setLoginData(response['data']['body']));
    yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("success", response['data']['message'])
    setTimeout(() => history.push('/home'), 1000);
  }
};


/*========== KYC FUNCTIONS =============*/

export function* getKYC() {
  const { error, response } = yield call(getCall, '/getPendingKyc');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put(setKYC(response['data']['body']));
  }
};

export function* getKycApproved() {
  const { error, response } = yield call(getCall, '/kycApproved');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put(setKycApproved(response['data']['body']));
  }
};

export function* updateKYC({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/kycUpdate",
    payload,
  });
  if (error) {
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(toggleStatusModal(false));
    yield put({ type: "GET_KYC" });
    EventBus.publish("success", response["data"]["message"]);
  }
}

export function* deleteKYC({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/deleteKYC",
    payload,
  });
  if (error) {
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(toggleStatusModal(false));
    yield put({ type: "GET_KYC" });
    EventBus.publish("success", response["data"]["message"]);
  }
}


/*========== KYB FUNCTIONS =============*/

function* getKYB() {
  const { error, response } = yield call(getCall, '/getPendingKyb');
  if (error) {
    yield put(toggleStatusModal(false));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(toggleStatusModal(false));
    yield put(setKYB(response['data']['body']));
  }
};

function* kybGlobal() {
  const { error, response } = yield call(getCall, '/kybGlobal/'+networkId);
  if (error) {
    yield put(toggleStatusModal(false));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(setKYB(response['data']['body']));
    yield put(toggleStatusModal(false));
  }
};

export function* getKybApproved() {
  const { error, response } = yield call(getCall, '/getKybApproved');
  console.log("*** error",error);
  console.log("*** response",response);
  if (error) {
    yield put(toggleStatusModal(false));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(toggleStatusModal(false));
    yield put(setKybApproved(response['data']['body']));
    EventBus.publish("success", response["data"]["message"]);
  }
}

export function* updateKYB({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/kybUpdate",
    payload,
  });
  if (error) {
    yield put(toggleStatusModal(false));
    EventBus.publish('error', error['response']['data']['message']);
  } else if (response) {
    yield put(toggleStatusModal(false));
    yield put({ type: "GET_KYB" });
    EventBus.publish("success", response["data"]["message"]);
  }
}

export function* deleteKYB({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/deleteKYB",
    payload,
  });
  if (error) {
    yield put(toggleStatusModal(false));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(toggleStatusModal(false));
    yield put({ type: "GET_KYB" });
    yield put(setKYB(response['data']['body']));
    EventBus.publish("success", response["data"]["message"]);
  }
}

//////////////////////////////////////////////////////////

export function* getAllDonations() {
  const { error, response } = yield call(getCall, '/getAllDonations');
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  } else if (response) {
    let donationContracts = response['data']['body'];
    let names = [];
    donationContracts.map((item) => {
      let donationName = item['contractName']
      names.push(donationName);
    });
    yield put(setAllDonations(response['data']['body']));
    yield put(setDonationsNames(names));
  }
};

export function* getUser({ payload }) {
  const { error, response } = yield call(postCall, { path: '/getUser', payload });
  if (error) {
    // EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setUser(response['data']['body']));
  }
};

function* getRewardTokens() {
  const { error, response } = yield call(getCall, '/getRewardToken');
  if (error) {
    // EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setRewardTokens(response['data']['body']));
  }
};

export function* getUriData({ payload }) {
  const { error, response } = yield call(postCall, { path: "/getUriData", payload });
  if (response) {
    yield put(setUriData(response['data']['body']));
  }
};


function* actionWatcher() {
  yield takeEvery('LOGIN', login);

  yield takeEvery('GET_KYC', getKYC);
  yield takeEvery('UPDATE_KYC', updateKYC);
  yield takeEvery('DELETE_KYC', deleteKYC);
  yield takeEvery('GET_KYC_APPROVED', getKycApproved);

  yield takeEvery('GET_KYB', getKYB);
  yield takeEvery('KYB_GLOBAL', kybGlobal);
  yield takeEvery('UPDATE_KYB', updateKYB);
  yield takeEvery('DELETE_KYB', deleteKYB);
  yield takeEvery('GET_KYB_APPROVED', getKybApproved);

  yield takeEvery('GET_ALL_DONATIONS', getAllDonations);
  yield takeEvery('GET_USER', getUser);
  yield takeEvery('GET_URI_DATA', getUriData);
  yield takeEvery('GET_REWARD_TOKENS', getRewardTokens);

};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
