const { web3 } = require('../web3');
const { env } = require('../config');

const SNFTData = require(`./${env}/SNFT.json`);
const ApproveData = require(`./${env}/Approve.json`);
const RewardNFTData = require(`./${env}/RewardNFT.json`);

const networks = {
  0: 'Disconnected',
  1: 'Mainnet',
  4: 'Rinkeby',
  42: 'Kovan',
}

const RewardNFTABI = RewardNFTData['abi'];
const RewardNFTBytecode = RewardNFTData['bytecode'];

const SNFTABI = SNFTData['abi'];
const SNFTAddress = SNFTData['address'];
const SNFT = new web3.eth.Contract(SNFTABI, SNFTAddress);

const ApproveABI = ApproveData['abi'];
const ApproveAddress = ApproveData['address'];
const Approve = new web3.eth.Contract(ApproveABI, ApproveAddress);

module.exports = {
  SNFT, SNFTABI, SNFTAddress,
  RewardNFTABI, RewardNFTBytecode,
  Approve, ApproveABI, ApproveAddress,
};
