import Papa from "papaparse";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3.js";
import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { validate } from "wallet-address-validator";
import { setLoader } from "../../store/actions/Auth.js";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SNFT, SNFTABI, SNFTAddress } from "../../store/contract/index.js";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import NFT from "../../assets/img/NFT.gif";
import modalcloseicon from "../../assets/img/close.png";
import copyicon from "../../assets/img/copy.png";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import './index.css';
import {
  Input,
} from "reactstrap";

const Airdrop = (props) => {

  const [txhash, settxhash] = useState("");
  const [addressFile, setaddressFile] = useState("");
  const [addressArray, setaddressArray] = useState([]);
  const [newaddressArray, setnewaddressArray] = useState([]);
  const [transferModal, setTransferModal] = useState(false);

  async function handleOnInput(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    if ([e.target.name] == "addressFile") {
      if (e.target.files[0] == undefined) {
        setaddressArray([]);
        setaddressFile("");
      } else {
        Papa.parse(e.target.files[0], {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            let valuesArray = [];

            // Iterating data to get column name and their values
            results.data.map((item) => {
              if (item['Address'] !== "") {
                valuesArray.push(item['Address']);
              }
            });

            // Parsed Data Response in array format
            setaddressArray(valuesArray);
          },
        });
        setaddressFile(e.target.files[0]);
      }
    }
  }

  async function airdrop(e) {
    try {

      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let publicAddress = (await web3.currentProvider.enable())[0];

      if (addressFile == "") {
        EventBus.publish("error", `Please upload csv!`);
        return;
      }

      if (addressArray == "") {
        EventBus.publish("error", `Please upload csv!`);
        return;
      }

      if (addressArray == undefined) {
        EventBus.publish("error", `Please upload csv!`);
        return;
      }

      if (addressArray.length > 100) {
        EventBus.publish("error", `Cannot airdrop to more than 100 address!`);
        return;
      }

      if (SNFTAddress == "") {
        EventBus.publish("error", `Please enter token address!`);
        return;
      }

      if (!SNFTAddress.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter token address`);
        return;
      }

      if (!SNFTAddress.match(/^[a-zA-Z0-9]+$/)) {
        EventBus.publish("error", `Invalid Token Address`);
        return;
      }

      let code = await web3.eth.getCode(SNFTAddress.toLowerCase());
      if (code === "0x") {
        EventBus.publish("error", `XRC721 Token Address is not a contract!`);
        return;
      }

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      let from = publicAddress;

      let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        from = "0x" + publicAddress.substring(3);
      } else {
        from = publicAddress;
      }

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      if (!validate(SNFTAddress, "ETH")) {
        EventBus.publish("error", `Please provide valid token address`);
        return;
      }

      let contract = new web3.eth.Contract(SNFTABI, SNFTAddress);

      let owner = await contract.methods.isMinter(publicAddress).call();

      if (!owner) {
        EventBus.publish("error", `You are not allowed to airdrop NFT!`);
        return;
      }

      newaddressArray.push("0x0000000000000000000000000000000000000000");

      addressArray.map(item => {
        let from = item;
        let output = item.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
        if (output == "xdc") {
          from = "0x" + item.substring(3);
        } else {
          from = item;
        }

        if (!validate(from, "ETH")) {
          EventBus.publish("error", `Invalid address detected in csv file!`);
          return;
        }

        newaddressArray.push(from);
      });

      console.log("********** newaddressArray", newaddressArray);

      props.setLoader({
        message: "Airdrop in Progress...",
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: 0,
          to: SNFTAddress,
          gas: 5000000,
          data: contract.methods
            .airdrop(newaddressArray)
            .encodeABI(),
        })
        .on('transactionHash', hash => {
          console.log(`************** deploy contract hash = ${hash}`);
          settxhash(hash);
        })
        .on('receipt', async receipt => {
          setaddressFile("");
          props.setLoader({ status: false });
          EventBus.publish("success", `NFT(s) Airdropped Successfully!`);
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Transfer Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Airdrop Failed`);
    }
  };

  async function copiedAddress() {
    EventBus.publish("success", "Transaction Hash Copied");
  }

  return (
    <div className="content">
      <div className="main-container mint-new">
        <div className="mint-new-NFT">
          <div className="edit-add airdrop ml-0">
            <div className="edit-add-title col-12 airdrop-head p-0 mb-3">
              <h1>Special NFT Airdrop</h1>

              <span>
                <a className="sample-csv-file" href="https://contractbanner.s3.us-east-2.amazonaws.com/test.csv" download>Sample CSV File</a>
              </span>
            </div>

            {
              txhash !== "" &&
              <p>
                <CopyToClipboard
                  text={txhash}
                  onCopy={copiedAddress}
                >
                  <a>{`Transaction Hash: ${txhash}`}</a>
                </CopyToClipboard>
              </p>
            }

            <div className="edit-add-body airdrop-wrap">
              <ValidatorForm onSubmit={airdrop}>
                <div className="form-group filewrap">
                  {addressFile == "" ?
                    <>
                      <span>Address CSV*</span>
                      <div className="upload-btn">Upload CSV file</div>
                      <Input
                        type="file"
                        name='addressFile'
                        placeholder="Address CSV"
                        accept=".csv"
                        onChange={handleOnInput}
                      />
                    </>
                    :
                    <>
                      <span>{addressFile ? (addressFile['name'] && addressFile['name'].substring(0, 10) + '...') : "Upload File*"}</span>
                      <div className="upload-btn">Upload File</div>
                      <Input
                        type="file"
                        name='addressFile'
                        placeholder={addressFile ? (addressFile['name'] && addressFile['name'].substring(0, 10) + '...') : "Upload File*"}
                        accept=".csv"
                        onChange={handleOnInput}
                      />
                    </>
                  }
                </div>

                <div className="edit-add-buttons">
                  <Button
                    className="submit-btn"
                    type="submit"
                  >
                    Airdrop
                  </Button>
                </div>
              </ValidatorForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

const mapDispatchToProps = {
  setLoader
};

const mapStateToProps = ({ Auth }) => {
  let { } = Auth;
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Airdrop);